










































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Newsletter } from "@/models/newsletter.interface";
import FilePicker from "@/components/Input/FilePicker.vue";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";

@Component({
  components: { PageTitle, Loader, Empty, FilePicker, BarSearch },
})
export default class Newsletters extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  $refs!: {
    campaignForm: HTMLFormElement;
  };
  dialog = false;
  loader = false;
  loading = false;
  loadingDT = false;
  subscriberID = "";
  selectedSubscriptors = [];
  dialogDelete = false;
  search = "";
  options = {};
  campaignFile: File | null = null;
  filePreview = "";
  defaultCampaign = {
    subject: "",
    message: "",
  };

  campaign = { ...this.defaultCampaign };

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async newsletterByText() {
    this.loadingDT = true;

    await this.getNewsletters(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getNewsletters(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("campaignFile")
  previewFile(file: File): void {
    if (file !== null) {
      this.filePreview = URL.createObjectURL(file);
    }
  }

  private async getNewsletters(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("newsletter/getNewsletters", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Newsletter.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async getAssignableSubscriptors() {
    await this.$store
      .dispatch("newsletter/getAssignableSubscriptors")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Newsletter.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getNewsletters(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    await this.getAssignableSubscriptors();
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Newsletter.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Newsletter.headers.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Newsletter.headers.email"),
        value: "email",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("LangPicker.language"),
        value: "language",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Newsletter.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get newsletters(): Newsletter[] {
    let response = this.$store.getters["newsletter/getNewsletters"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.subscriptions) {
      return response.subscriptions;
    } else {
      return [];
    }
  }

  private get campaignProgress(): number {
    return this.$store.getters["newsletter/getCampaignProgress"];
  }

  private get subscriptors(): Newsletter[] {
    return this.$store.getters["newsletter/getSubscriptors"];
  }

  private close() {
    this.dialog = false;
    this.campaign = Object.assign(this.campaign, this.defaultCampaign);
    this.filePreview = "";
    this.campaignFile = null;
    this.selectedSubscriptors = [];
    this.resetFormValidations([this.$refs.campaignForm]);
  }

  private async submit() {
    this.loading = true;
    if (this.$refs.campaignForm.validate()) {
      const payload = new FormData();
      payload.append(
        "DATA",
        JSON.stringify({
          subject: this.campaign.subject,
          message: this.campaign.message,
          subscriptors: this.selectedSubscriptors,
        })
      );
      payload.append("NEWSLETTER", this.campaignFile as File);
      await this.$store
        .dispatch("newsletter/sendCampaign", payload)
        .then(() => {
          const Success: Notification = {
            message: this.$tc("Newsletter.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Newsletter.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.$store.dispatch("newsletter/clearProgress");
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async deleteSubscriberConfirm(id: string) {
    this.subscriberID = id;
    this.dialogDelete = true;
  }

  private async deleteSubscriber() {
    this.loading = true;
    await this.$store
      .dispatch("newsletter/removeSubscriber", this.subscriberID)
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Newsletter.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getNewsletters(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        await this.getAssignableSubscriptors();
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Newsletter.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.subscriberID = "";
  }

  private get addAllSubscribers() {
    return this.selectedSubscriptors.length === this.subscriptors.length;
  }

  private get addSomeSubscribers() {
    return this.selectedSubscriptors.length > 0 && !this.addAllSubscribers;
  }

  private get icon() {
    if (this.addAllSubscribers) return "mdi-close-box";
    if (this.addSomeSubscribers) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  private toggle() {
    this.$nextTick(() => {
      if (this.addAllSubscribers) {
        this.selectedSubscriptors = [];
      } else {
        this.selectedSubscriptors = this.subscriptors.slice() as never;
      }
    });
  }
}
